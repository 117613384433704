/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
    margin: 0px;
}

body {
    /* background-color: lightgray; */
    background-color: #f2f2f2;
}
  
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.primary {
    font-size: 16px;
    color: cornflowerblue;
}
  
.secondary {
    font-size: 16px;
    color: black;
}

.heading {
    margin-top: 5px;
    margin-bottom: 5px;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #0057e7;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #0057e7;
    }
    80%,
    90% {
        stroke: #0057e7;
    }
}
